import React from 'react'
import Market1 from '../images/daily-price.svg'
import Market2 from '../images/daily-txn.svg'

const ChainCharts = () => {
  return (
    <>
      <section className='sec01_p'>
        <div className='container'>
          <div className='box1'>
                <ul class="nav nav-tabs chart_tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Market Data</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Blockchain Data</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Network Data</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link" id="statistics-tab" data-bs-toggle="tab" data-bs-target="#statistics" type="button" role="tab" aria-controls="statistics" aria-selected="false">Top Statistics</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link" id="contracts-tab" data-bs-toggle="tab" data-bs-target="#contracts" type="button" role="tab" aria-controls="contracts" aria-selected="false">Contracts</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Market Capitalization Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Market Capitalization Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className='row'>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Daily Transactions Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>ERC-20 Daily Token Transfer Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Unique Addresses Chart</h5>
                                <img src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Average Block Size Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Average Block Time Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Average Gas Price Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className='row'>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Network Pending Transactions Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Network Transaction Fee Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Network Utilization Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Node Tracker</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Market Capitalization Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="statistics" role="tabpanel" aria-labelledby="statistics-tab">
                <div className='row'>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>DEX Activity</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Top 25 Validators by Blocks</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Market Capitalization Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="contracts" role="tabpanel" aria-labelledby="contact-tab">
                <div className='row'>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Polygon PoS Chain Daily Verified Contracts Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>Polygon PoS Chain Daily Deployed Contracts Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Market Capitalization Chart</h5>
                                <img className='img1' src={Market2} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-3'>
                            <div className='box1'>
                                <h5 className='chart_title'>MATIC Daily Price (USD) Chart</h5>
                                <img className='img1' src={Market1} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
          </div>  
        </div>
      </section>
    </>
  )
}

export default ChainCharts
