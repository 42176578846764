import logo from './logo.svg';
import './App.css';
import Home from './block/Home'
import Mainpage from './block/Mainpage'

import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Header from './block/Header';
import Footer from './block/Footer';
import Transactions from './block/Transactions'
import Pendingtransactions from './block/Pendingtransactions'
import ContractInternal from './block/ContractInternal'
import Plasmadeposit from './block/Plasmadeposit'
import Viewblock from './block/Viewblock'
import ForkedBlocks from './block/ForkedBlocks'
import TopAccounts from './block/TopAccounts'
import VerifiedContracts from './block/VerifiedContracts'
import TokenTracker from './block/TokenTracker'
import TokenTransfers from './block/TokenTransfers'
import TopNFTs from './block/TopNFTs'
import NFTTopMints from './block/NFTTopMints'
import NFTTrades from './block/NFTTrades'
import NFTLatestMints from './block/NFTLatestMints'
import NFTTransfers from './block/NFTTransfers'
import ChainCharts from './block/ChainCharts'
import APIPlans from './block/APIPlans'
import APIDocumentation from './block/APIDocumentation'
import Signin from './block/Signin'
const Public =()=>{
  return(
    <>
      <Header/>
        <Outlet/>
      <Footer/>
    </>
  )
}

function App() {
  return (
    <>
    <Router>
        <Routes>
          <Route element={<Public/>}>
          <Route path="/" element={<Home />} />
          <Route path="Mainpage" element={<Mainpage />} />
          <Route path="Transactions" element={<Transactions />} />
          <Route path="Pendingtr" element={<Pendingtransactions />} />
          <Route path="ContractInternal" element={<ContractInternal />} />
          <Route path="Plasmadeposit" element={<Plasmadeposit />} />
          <Route path="Viewblock" element={<Viewblock />} />
          <Route path="ForkedBlocks" element={<ForkedBlocks />} />
          <Route path="TopAccounts" element={<TopAccounts />} />
          <Route path="VerifiedContracts" element={<VerifiedContracts />} />
          <Route path="TokenTracker" element={<TokenTracker />} />
          <Route path="TokenTransfers" element={<TokenTransfers />} />
          <Route path="TopNFTs" element={<TopNFTs />} />
          <Route path="NFTTopMints" element={<NFTTopMints />} />
          <Route path="NFTTrades" element={<NFTTrades />} />
          <Route path="NFTLatestMints" element={<NFTLatestMints />} />
          <Route path="NFTTransfers" element={<NFTTransfers />} />
          <Route path="ChainCharts" element={<ChainCharts />} />
          <Route path="APIPlans" element={<APIPlans />} />
          <Route path="APIDocumentation" element={<APIDocumentation />} />
          <Route path="Signin" element={<Signin />} />
            </Route>
            
            </Routes>
            
            </Router>
    </>
  );
}

export default App;
