import React from 'react'
import web from '../images/wETH_32.webp'

const TokenTracker = () => {
  return (
    <>
      <section className='sec01_p'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>Token Tracker (ERC-20)</h3>
                        <p className='trans_title bolor_b'>( A total of 249 pending txns found )</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
                <div className='table_over'>
                    <div className='table_main'>
                        <table className='TokenTracker_table'>
                            <tr className='table_row'>
                                <th className='table_heading'>S No</th>
                                <th className='table_heading'> Token	</th>
                                <th className='table_heading'>Price</th>
                                <th className='table_heading'>Change (%)</th>
                                <th className='table_heading'>Volume (24H)</th>
                                <th className='table_heading'>Circulating Market Cap</th>
                                <th className='table_heading'>Onchain Market Cap </th>
                                <th className='table_heading'>Holders</th>
                            </tr>
                            <tr className='table_row'>
                                <td className='table_text'>1</td>
                                <td className='table_text'><img src={web} /> Wrapped Ether</td>
                                <td className='table_text'>$2,993.868 <br/> <span className='gr'>4,374.997137 MATIC</span></td>
                                <td className='table_text r'>-0.26%	</td>
                                <td className='table_text'>$11,102,915,585.00	</td>
                                <td className='table_text'>$359,579,243,325.00   	</td>
                                <td className='table_text'>$570,670,162.00   	</td>
                                <td className='table_text'>1,812,560 <br/> <span className='g'>0.007%</span></td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default TokenTracker
