import React from 'react'
import api from '../images/api-hero-light.svg'


const APIPlans = () => {
  return (
    <>
      <section className='sec01_p'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self'>
                        <h6 className='sub_title'>BK API</h6>
                        <h2 className='banner-title'>Build Precise & Reliable Apps with <span className='bl'> BK APIs</span></h2> 
                        <p className='banner_text'>Data from the leading Polygon PoS Chain Block Explorer catered to your needs.</p> 
                        <a className='btn_api btn_api1'>API Pricing Plans</a> 
                        <a className='btn_api btn_api2'>API Documentation </a>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img src={api} className='api-img' />
                    </div>
                </div>
            </div>
      </section>
      <section className='sec02'>
            <div className='container'>
                <h2 className='banner-title'>Ready to get started?</h2>
                <p className='banner_text'>Select the plan that best suits you or contact us for a custom option.</p>
                <ul class="nav nav-tabs chart_tabs api_tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Monthly</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Quarterly (10% Off)</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="tab_btn nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Yearly (20% Off)</button>
                    </li>
                    
                </ul>
                <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                   <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>FREE</h6>
                                <h3 className='api_price bl'>$0 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  5 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Community support</li>
                                    <li className='api_list_item'>. </li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>STANDARD</h6>
                                <h3 className='api_price bl'>$199 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   10 calls/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 200,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>ADVANCED</h6>
                                <h3 className='api_price bl'>$299 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  20 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 500,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>PROFESSIONAL</h6>
                                <h3 className='api_price bl'>$399 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  30 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                   </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>FREE</h6>
                                <h3 className='api_price bl'>$0 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  5 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Community support</li>
                                    <li className='api_list_item'>. </li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>STANDARD</h6>
                                <h3 className='api_price bl'>$199 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   10 calls/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 200,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>ADVANCED</h6>
                                <h3 className='api_price bl'>$299 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  20 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 500,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>PROFESSIONAL</h6>
                                <h3 className='api_price bl'>$399 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  30 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                   </div>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>FREE</h6>
                                <h3 className='api_price bl'>$0 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  5 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Community support</li>
                                    <li className='api_list_item'>. </li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>STANDARD</h6>
                                <h3 className='api_price bl'>$179.10 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   10 calls/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 200,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>ADVANCED</h6>
                                <h3 className='api_price bl'>$239.20 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  20 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 500,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-3'>
                            <div className='box1'>
                                <h6 className='sub_title'>PROFESSIONAL</h6>
                                <h3 className='api_price bl'>$319.20 <span className='mo'>( Mo )</span></h3>
                                <ul className='api_list'>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  30 call/second limit</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Up to 100,000 API calls/day</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  All existing community endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>   Access to API Pro endpoints</li>
                                    <li className='api_list_item'><i class="fa-regular fa-circle-check bl me-2"></i>  Escalated support</li>
                                </ul>
                                <a href="#" class="view-btn ">Get Started Now</a>
                            </div>
                        </div>
                   </div>
                </div>
                
                </div>
            </div>
      </section>
      <section className='sec03'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 border-right col_01'>
                        <h2 className='faq-title'> Frequently Asked Questions</h2>
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                How do I subscribe to BscScan API services?
                            </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="faq-text baner-text w inter">Kindly visit the API self-checkout section above select the plan that fits you best and follow the steps accordingly.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                How do I Upgrade or Cancel an account?
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <p class="faq-text baner-text w inter">Please contact us  should you wish to upgrade or cancel your account. We will assist you accordingly.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                What are the Payment Options available?
                            </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <p class="faq-text baner-text w inter">We accept major credit cards that are supported by Stripe.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                            What is your refund policy?
                            </button>
                            </h2>
                            <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <p class="faq-text baner-text w inter">Payments made are non-refundable and we do not provide refunds or credits for any services already paid for.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                            When will Account Activation occur?
                            </button>
                            </h2>
                            <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <p class="faq-text baner-text w inter">API Pro accounts will be activated within 24 hours after username and email has been received.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                            <button class="accordion-button collapsed faq_btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                            How does Renewal work for API Pro accounts?
                            </button>
                            </h2>
                            <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <p class="faq-text baner-text w inter">Stripe billing is auto-renewed by default. Unless you cancel your subscription plan before your billing date, you authorise us to automatically charge the subscription fee for the next billing cycle to your account.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col_02'>
                        <h2 className='faq-title'>Contact Us</h2>
                       <form className='api_form'>
                            <label className='form_label'>Contact Name</label>
                            <input type='text' className='form_input1 form-control' />
                            <label className='form_label'>Company Email Address</label>
                            <input type='email' className='form_input1 form-control' />
                            <label className='form_label'>Message</label>
                            <textarea className='form_input1 form-control'></textarea>
                            <input type='submit' className='api_form_s' value='Submit' />
                       </form> 
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default APIPlans
