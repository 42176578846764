    import React from 'react'

    const Pendingtransactions = () => {

        const copyText = () => {
        
            navigator.clipboard.writeText
                ("Geeksforgeeks is best learning platformqwertyuio.");
        }

    return (
        <>
        <section className='sec01_p'>
            <div className='container'>
                <div className='box1'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-8 col-sm-6'>
                            <h3 className='transactions_title b'>Pending Transactions</h3>
                            <p className='trans_title bolor_b'>( A total of 249 pending txns found )</p>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                        <nav aria-label="Page navigation example d-flex">
                            <ul class="pagination">
                                <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </a>
                                </li>
                            </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='table_over'>
                        <div className='table_main'>
                            <table className='Pending_t_table'>
                                <tr className='table_row'>
                                    <th className='table_heading'>#</th>
                                    <th className='table_heading'>Transaction Hash	</th>
                                    <th className='table_heading'>Method</th>
                                    <th className='table_heading'>Nonce</th>
                                    <th className='table_heading'>Last Seen	</th>
                                    <th className='table_heading'>Gas Limit</th>
                                    <th className='table_heading'>Gas Price</th>
                                    <th className='table_heading'>From</th>
                                    <th className='table_heading'>To</th>
                                    <th className='table_heading'>Amount</th>
                                </tr>
                                <tr className='table_row'>
                                    <td className='table_text'>1</td>
                                    <td className='table_text'>
                                        <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                        <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                                    </td>
                                    <td className='table_text'><span className='btn_v'>Claim Key</span></td>
                                    <td className='table_text'>79</td>
                                    <td className='table_text'>5 secs ago</td>
                                    <td className='table_text'>33881</td>
                                    <td className='table_text'>29.1 Gwei</td>
                                    <td className='table_text'>
                                        <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                        <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                                    </td>
                                    <td className='table_text'>
                                        <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                        <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                                    </td>
                                    <td className='table_text'>4.000</td>
                                    <td className='table_text'>0.000000001</td>
                                </tr>
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
    }

    export default Pendingtransactions
