import React from 'react'

const TopAccounts = () => {

    const copyText = () => {
    
        navigator.clipboard.writeText
            ("Geeksforgeeks is best learning platformqwertyuio.");
    }
  return (
    <>
       <section className='sec01_p'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>Top Accounts by MATIC Balance</h3>
                        <p className='trans_title bolor_b'>More than 1,999,999 accounts found (9,908,000,000 MATIC)</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>

                <div className='table_main'>
                    <table className='topAccounts_table'>
                        <tr className='table_row'>
                            <th className='table_heading'>S No</th>
                            <th className='table_heading'>Address</th>
                            <th className='table_heading'>Name Tag	</th>
                            <th className='table_heading'> Balance	</th>
                            <th className='table_heading'>Percentage	</th>
                            <th className='table_heading'>Txn Count</th>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>1</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>Polygon: MATIC Token	</td>
                            <td className='table_text'>8,628,465,283.50858187 MATIC	</td>
                            <td className='table_text'>87.08584259%	</td>
                            <td className='table_text'>341,841</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default TopAccounts
