import React from 'react'

const Footer = () => {
  return (
    <>
       <footer>
            <div className='row'>
                <div className='col-lg-2 col-md-2'>
                    <p className='text_p '>Data provided by </p>
                </div>  
                <div className='col-lg-10 col-md-10 text-end'>
                        <ul className='list_footer mb-0'>
                            <li className='list_footer_item'> <a href='/'> Faq</a> </li>
                            <li className='list_footer_item'> <a href='/'> Release monitor</a> </li>
                            {/* <li className='list_footer_item'> <a href='/'> Terms and conditions</a> </li> */}
                            <li className='list_footer_item'> <a href='/'> Privacy policy</a> </li>
                            <li className='list_footer_item'> <a href='/'> Cookies policy</a> </li>
                        </ul>
                </div>

            </div>
        </footer>
    </>
  )
}

export default Footer
