import React, { useState } from 'react'; 
import logo from '../images/logotimex.png';
import qr from '../images/qr-code.png';  
import Menu from '../images/menu.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const handleToggle = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleLinkClick = () => {
        setSidebarVisible(false); // Close the sidebar
    };

    return (
        <>
            <header>
                <div className='container'>
                    <div className='nav_bar d-flex'>
                        <div className='logo_box'>
                            <img src={logo} className='logo' alt="Logo" />
                        </div>
                        <div className='nav_item d-flex'>
                            <div className="dropdown">
                                <button 
                                    className="btn btn-secondary dropdown-toggle" 
                                    type="button" 
                                    onClick={handleToggle}
                                >
                                    <img className='menu_t' src={Menu} alt="Menu" />
                                </button>

                                {sidebarVisible && (
                                    <ul className="dropdown-menu show">
                                        <li><NavLink className="dropdown-item" to="/" onClick={handleLinkClick}>Home</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Mainpage" onClick={handleLinkClick}>Mainpage</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Transactions" onClick={handleLinkClick}>Transactions</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Pendingtr" onClick={handleLinkClick}>Pendingtr</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ContractInternal" onClick={handleLinkClick}>Contract Internal</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Plasmadeposit" onClick={handleLinkClick}>Plasma Deposit</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Viewblock" onClick={handleLinkClick}>View Block</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ForkedBlocks" onClick={handleLinkClick}>Forked Blocks</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/TopAccounts" onClick={handleLinkClick}>Top Accounts</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/VerifiedContracts" onClick={handleLinkClick}>Verified Contracts</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/TokenTracker" onClick={handleLinkClick}>Token Tracker</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/TokenTransfers" onClick={handleLinkClick}>Token Transfers</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/TopNFTs" onClick={handleLinkClick}>Top NFTs</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/NFTTopMints" onClick={handleLinkClick}>NFT Top Mints</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/NFTTrades" onClick={handleLinkClick}>NFT Trades</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/NFTLatestMints" onClick={handleLinkClick}>NFT Latest Mints</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/NFTTransfers" onClick={handleLinkClick}>NFT Transfers</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ChainCharts" onClick={handleLinkClick}>Chain Charts</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/APIPlans" onClick={handleLinkClick}>API Plans</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/APIDocumentation" onClick={handleLinkClick}>API Documentation</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Signin" onClick={handleLinkClick}>Sign In</NavLink></li>
                                    </ul>
                                )}
                            </div>

                            <div className='d-flex scan_icon'>
                                <img className='qr' src={qr} alt="QR Code" />
                                <span>Scan Qr</span>
                            </div>
                        </div>
                    </div> 
                </div>
            </header> 
        </>
    );
}

export default Header;
