import React from 'react'

const APIDocumentation = () => {
  return (
    <>
      <section className='sec01_p'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-md-4 col-sm-5'>
                    <div className='box1'>
                          <h4 className='tab_main_title bl mt-0'>GETTING STARTED</h4>
                          <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="nav-link tab_nav_lin active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Creating an Account</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Getting an API Key</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Endpoint URLs</button>

                            <h4 className='tab_main_title bl'>API ENDPOINTS</h4>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Accounts</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab1" data-bs-toggle="pill" data-bs-target="#v-pills-settings1" type="button" role="tab" aria-controls="v-pills-settings1" aria-selected="false">Transactions</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-settings2" type="button" role="tab" aria-controls="v-pills-settings2" aria-selected="false">Blocks</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-settings3" type="button" role="tab" aria-controls="v-pills-settings3" aria-selected="false">Logs</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-settings4" type="button" role="tab" aria-controls="v-pills-settings4" aria-selected="false">Geth Proxy</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-settings5" type="button" role="tab" aria-controls="v-pills-settings5" aria-selected="false">Tokens</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-settings6" type="button" role="tab" aria-controls="v-pills-settings6" aria-selected="false">Gas Tracker</button>
                            <button class="nav-link tab_nav_lin" id="v-pills-settings-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-settings7" type="button" role="tab" aria-controls="v-pills-settings7" aria-selected="false">Stats</button>
                            
                           <h4 className='tab_main_title bl'>API PRO</h4>
                           <button class="nav-link tab_nav_lin" id="v-pills-settings-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-settings8" type="button" role="tab" aria-controls="v-pills-settings8" aria-selected="false">PolygonScan API PRO</button>
                           <button class="nav-link tab_nav_lin" id="v-pills-settings-tab9" data-bs-toggle="pill" data-bs-target="#v-pills-settings9" type="button" role="tab" aria-controls="v-pills-settings9" aria-selected="false">API PRO Endpoints</button>

                          </div>
                    </div>
                </div>
                <div className='col-lg-9 col-md-8 col-sm-7'>
                    <div className='box1'>
                          
                          <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">..1.</div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">..2.</div>
                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">..3.</div>
                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">.4..</div>
                            <div class="tab-pane fade" id="v-pills-settings1" role="tabpanel" aria-labelledby="v-pills-settings-tab1">.5..</div>
                            <div class="tab-pane fade" id="v-pills-settings2" role="tabpanel" aria-labelledby="v-pills-settings-tab2">.6..</div>
                            <div class="tab-pane fade" id="v-pills-settings3" role="tabpanel" aria-labelledby="v-pills-settings-tab3">.7..</div>
                            <div class="tab-pane fade" id="v-pills-settings4" role="tabpanel" aria-labelledby="v-pills-settings-tab4">.8..</div>
                            <div class="tab-pane fade" id="v-pills-settings5" role="tabpanel" aria-labelledby="v-pills-settings-tab5">.9..</div>
                            <div class="tab-pane fade" id="v-pills-settings6" role="tabpanel" aria-labelledby="v-pills-settings-tab6">.10..</div>
                            <div class="tab-pane fade" id="v-pills-settings7" role="tabpanel" aria-labelledby="v-pills-settings-tab7">.11..</div>
                            <div class="tab-pane fade" id="v-pills-settings8" role="tabpanel" aria-labelledby="v-pills-settings-tab8">.12..</div>
                            <div class="tab-pane fade" id="v-pills-settings9" role="tabpanel" aria-labelledby="v-pills-settings-tab9">.13..</div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default APIDocumentation
