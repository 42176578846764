import React from 'react'
import sing from '../images/sing_in.png'
const Signin = () => {
  return (
    <>
      <section className='sec01_p'>
        <div className='container'>
            <div className='sing_form_box box1 mx-auto'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <img className='sing_img' src={sing} />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <h2 className='faq-title text-center'>Sign In</h2>
                    <p className='baaner-text text-center'>Don't have an account? <a href='/'> Sign Up</a></p>
                       <form className='api_form'>
                            <label className='form_label'>Username</label>
                            <input type='text' className='form_input1 form-control' />
                            <label className='form_label'>Password</label>
                            <input type='password' className='form_input1 form-control' />
                            <input type='checkbox' />
                            <label className='form_label ms-2'>Remember & Auto Login</label>
                            <div className='text-center'>
                                <input type='submit' className='api_form_s login_btn' value='Login' />
                            </div>
                       </form> 
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}


export default Signin
