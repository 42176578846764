import React from 'react'

const VerifiedContracts = () => {

    const copyText = () => {
    
        navigator.clipboard.writeText
            ("Geeksforgeeks is best learning platformqwertyuio.");
    }
  return (
    <>
      <section className='sec01_t'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Contracts Deployed (Total)</p>
                        <h4 className='trans_text b'>13,653,125</h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Contracts Deployed (24h)</p>
                        <h4 className='trans_text b'>238,260</h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Contracts Verified (Total)</p>
                        <h4 className='trans_text b'>218,306 </h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                        <div className='box1'>
                            <p className='trans_title bolor_b'>Contracts Verified (24h)</p>
                            <h4 className='trans_text b'> 189</h4>
                        </div>
                </div>
            </div>
        </div>
    </section> 
    <section className='sec02_t'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>Verified Contracts</h3>
                        <p className='trans_title bolor_b'>(  Showing the last 500 verified contracts source code )</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>

                <div className='table_main'>
                    <table className='block_table'>
                        <tr className='table_row'>
                            <th className='table_heading'>S No</th>
                            <th className='table_heading'>Address	</th>
                            <th className='table_heading'>Contract Name	</th>
                            <th className='table_heading'>Compiler	</th>
                            <th className='table_heading'>Version</th>
                            <th className='table_heading'>Balance	</th>
                            <th className='table_heading'>Txns		</th>
                            <th className='table_heading'>Verified	</th>
                            <th className='table_heading'>License	</th>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>1</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>matrix</td>
                            <td className='table_text'>Solidity	</td>
                            <td className='table_text'>65</td>
                            
                            <td className='table_text'>0.8.20	</td>
                            <td className='table_text'>0 MATIC		</td>
                            <td className='table_text'>5/9/2024		</td>
                            <td className='table_text'>None</td>
                        </tr>
                        
                    </table>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default VerifiedContracts
