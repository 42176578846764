    import React from 'react'
    import phone from '../images/phone.png'
    import Transaction from '../images/transaction.png'
    import Pricing from '../images/pricing.png'
    import Stake from '../images/stake-holders.png'
    import Supply from '../images/supply-chain.png'
    import Chart from '../images/chart.png'
    import Cube from '../images/cube.png'

    // import chart from '../block/chart'
    const Mainpage = () => {
    return (
        <>
            <section className='main_page_sec01'>
                <div className='container'>
                    <div className='row col-rev'>
                        <div className='col-lg-6 col-md-6 align-self'>
                            <h1 className='main_heading_h1'>MOST SECURE CRYPTO TRADING PLATFORM</h1>

                            <form class="d-flex search-form mt-3">
                                <div class="user-icon">
                                    <i class="fa-solid fa-filter"></i>
                                </div>
                                <select name="f" class="form-select fs-base border-0 filterby w">
                                    <option class="b" value="0">filter</option>
                                    <option class="b" value="1">Addresses</option>
                                    <option class="b" value="2">Tokens</option>
                                    <option class="b" value="3">Name Tags</option>
                                    <option class="b" value="4">Labels</option>
                                    <option class="b" value="5">Websites</option>
                                </select>
                                <input class="form-control me-2 search-input inter" type="search" placeholder="Search" aria-label="Search" value="" />
                                    <button class="search-btn" type="submit">
                                        <i class="fa-brands fa-searchengin b"></i>
                                    </button>
                            </form>
                        </div>
                        <div className='col-lg-6 col-md-6 text-center align-self'>
                            <img className='phone' src={phone} />
                        </div>
                    </div>
                </div>
            </section>
            <section className='sec02'>
                <div className='container'>
                    <div className='row box1'>
                        <div className='col-lg-6 col-md-6 px-0'>
                            <div className=''>
                                <div className='box_item box_item1'>
                                    <div className='list d-flex'>
                                        <div className='list_item1'>
                                            <img className='list_item_img' src={Supply} />
                                            <span className='list_item_title'>Supply</span>
                                        </div>
                                        <div className='list_item2 align-self'>
                                            <h4 className='list_item_text'><i class="fa-solid fa-chart-simple me-2 g"></i> 3000,000,00</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_item box_item2'>
                                    <div className='list d-flex'>
                                        <div className='list_item1'>
                                            <img className='list_item_img' src={Transaction} />
                                            <span className='list_item_title'>TRANSACTIONS</span>
                                        </div>
                                        <div className='list_item2 align-self'>
                                            <h4 className='list_item_text'><i class="fa-solid fa-chart-simple me-2 r"></i> 780,300,85</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_item box_item3'>
                                    <div className='list d-flex'>
                                        <div className='list_item1'>
                                            <img className='list_item_img' src={Stake} />
                                            <span className='list_item_title'>Holders</span>
                                        </div>
                                        <div className='list_item2 align-self'>
                                            <h4 className='list_item_text'><i class="fa-solid fa-chart-simple me-2 g"></i> 8700,500,40</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_item box_item4 mb-0'>
                                    <div className='list d-flex'>
                                        <div className='list_item1'>
                                            <img className='list_item_img' src={Pricing} />
                                            <span className='list_item_title'>Price</span>
                                        </div>
                                        <div className='list_item2 align-self'>
                                            <h4 className='list_item_text'><i class="fa-solid fa-chart-simple me-2 r"></i> 250,850,07</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='text-center'>
                                {/* <chart/> */}
                                <img className='chart' src={Chart} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='main_sec03'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='box1'>
                                <h3 className='transactions_title b mb-3'>Latest Blocks</h3>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.5386</span>
                                    </div>
                                </div>

                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.538</span>
                                    </div>
                                </div>

                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...6020</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.538</span>
                                    </div>
                                </div>

                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.538</span>
                                    </div>
                                </div>

                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.538</span>
                                    </div>
                                </div>


                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>566</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>Validated By <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <p className='icon_box_time mb-0 bolor_b'>118 txns in 2 secs</p>
                                    </div>

                                    <div>
                                        <span className='btn_v'>0.538</span>
                                    </div>
                                </div>

                                <div className='view_btn_box'>
                                    <a href='/' className='view_btn b'>View all blocks</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 l_t'>
                            <div className='box1'>
                                <h3 className='transactions_title b mb-3'>Latest Transactions</h3>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>456</span>
                                    </div>
                                </div>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>5669</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>879</span>
                                    </div>
                                </div>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>798</span>
                                    </div>
                                </div>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>782</span>
                                    </div>
                                </div>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>254</span>
                                    </div>
                                </div>
                                <div className='transac d-flex'>
                                    <div className='icon_box d-flex'>
                                        <div className='icon_box_i  align-self'>
                                            <span>BK</span>
                                        </div>
                                        <div className='icon_box_c'>
                                                <h4 className='icon_box_title'>56672100</h4>
                                                <p className='icon_box_time mb-0'>30 secs ago</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className='validated_title mb-0'>From <span className='bolor_b'>0xa8...22D7</span></h3>
                                        <h3 className='validated_title mb-0'>To <span className='bolor_b'>0x0f...0f72</span></h3>
                                    </div>

                                    <div>
                                        <span className='btn_v'>125</span>
                                    </div>
                                </div>

                                <div className='view_btn_box'>
                                    <a href='/' className='view_btn b'>View all transactions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    }

    export default Mainpage
