import React from 'react'

const Viewblock = () => {

    const copyText = () => {
    
        navigator.clipboard.writeText
            ("Geeksforgeeks is best learning platformqwertyuio.");
    }
  return (
    <>
       <section className='sec01_t'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Network Utilization (24H)</p>
                        <h4 className='trans_text b'>44.7%</h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>BLOCK SIZE (24H)</p>
                        <h4 className='trans_text b'>52,662 <span className='b'>(Bytes)</span></h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>BLOCK REWARDS (24H)</p>
                        <h4 className='trans_text b'>31,505  <span className='g'>(MATIC)</span></h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                        <div className='box1'>
                            <p className='trans_title bolor_b'>Burnt Fees 🔥</p>
                            <h4 className='trans_text b'> 0.00  <span className='g'>(MATIC)</span></h4>
                        </div>
                </div>
            </div>
        </div>
    </section> 
    <section className='sec02_t'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>Blocks</h3>
                        <p className='trans_title bolor_b'>( Total of 56,751,948 blocks )</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>

                <div className='table_main'>
                    <table className='block_table'>
                        <tr className='table_row'>
                            <th className='table_heading'>S No</th>
                            <th className='table_heading'>Block</th>
                            <th className='table_heading'>Age</th>
                            <th className='table_heading'>Txn</th>
                            <th className='table_heading'>Validator</th>
                            <th className='table_heading'>Gas Used	</th>
                            <th className='table_heading'>Gas Limit	</th>
                            <th className='table_heading'>Base Fee	</th>
                            <th className='table_heading'>Reward	</th>
                            <th className='table_heading'>Burnt Fees (MATIC)</th>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>1</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>3 mins ago	</td>
                            <td className='table_text'>65</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>9,040,344 (30% | -40%)</td>
                            <td className='table_text'>30,000,000	</td>
                            <td className='table_text'>0 Gwei</td>
                            <td className='table_text'>0.43733 MATIC	</td>
                            <td className='table_text'>0.000000 (0.00%)</td>
                        </tr>
                        
                    </table>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Viewblock
