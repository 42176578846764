import React from 'react'

const Transactions = () => {

    const copyText = () => {
    
        navigator.clipboard.writeText
            ("Geeksforgeeks is best learning platformqwertyuio.");
    }
  return (
    <>
     <section className='sec01_t'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Transactions (24H)</p>
                        <h4 className='trans_text b'>3,863,079 <span className='g'>(10.22%)</span></h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Pending Transactions (Last 1H)</p>
                        <h4 className='trans_text b'>265 <span className='b'>(Average)</span></h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                    <div className='box1'>
                        <p className='trans_title bolor_b'>Network Transactions Fee (24H)</p>
                        <h4 className='trans_text b'>31,939.60 MATIC <span className='g'>(31.82%)</span></h4>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 mt-3'>
                        <div className='box1'>
                            <p className='trans_title bolor_b'>Avg. Transaction Fee (24h)</p>
                            <h4 className='trans_text b'>0.0059 USD <span className='g'>(16.32%)</span></h4>
                        </div>
                </div>
            </div>
        </div>
    </section> 
    <section className='sec02_t'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-6 col-sm-6 col-6'>
                        <h3 className='transactions_title b'>Latest Transactions</h3>
                        <p className='trans_title bolor_b'>(Showing the last 500k records)</p>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>

                <div className='table_main'>
                    <table className='transactions_table'>
                        <tr className='table_row'>
                            <th className='table_heading'>#</th>
                            <th className='table_heading'>Block</th>
                            <th className='table_heading'>Txn Hash</th>
                            <th className='table_heading'>Age</th>
                            <th className='table_heading'>From</th>
                            <th className='table_heading'>To</th>
                            <th className='table_heading'>Value</th>
                            <th className='table_heading'>Txn fee</th>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>1</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>2</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>3</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>4</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>5</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>6</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>7</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>8</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>9</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                        <tr className='table_row'>
                            <td className='table_text'>10</td>
                            <td className='table_text'>2937679</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>1 days</td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>
                                <p class="icon-box-sub1 d-flex b mb-0"> <span id="sample" class="g"><a href="#" class="bhcjb b">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                <button class="copy-btn" onClick={copyText}><i class="fa-regular fa-copy g"></i></button></p>
                            </td>
                            <td className='table_text'>4.000</td>
                            <td className='table_text'>0.000000001</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>

  
    </>
  )
}

export default Transactions
