import React from 'react'
import logo from '../images/logo.png'
import qr from '../images/qr-code.png'
import search from '../images/search.png'
import btc from '../images/btc.svg'

const Home = () => {
  return (
    <>
     
        <section className='sec01'>
                <div className='container'>
                    <h1 className='main_heading text-center'>Search for block, transaction, address...</h1>

                    <div className='form_box'>
                        <form className='d-flex ckjh'>
                            <input type='text' className='form_input' placeholder='Block hash, transaction hash, address hash...' /> 
                            <button type='submit' className='search_btn'> <img src={search} className='search' /> </button>
                        </form>
                    </div>

                    <div className='img_row'>
                        <ul className='list_img'>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            {/* <li className='list_img_item'> <img src={btc} /></li> */}
                            {/* <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li>
                            <li className='list_img_item'> <img src={btc} /></li> */}
                        </ul>
                    </div>
                </div>
        </section>
       
    </>
  )
}

export default Home
