import React from 'react'
import web from '../images/wETH_32.webp'

const TopNFTs = () => {
  return (
    <>
      <section className='sec01_p'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>Top NFTs</h3>
                        <p className='trans_title bolor_b'>( Showing the top 33 NFTs )</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
                <div className='table_over'>
                    <div className='table_main'>
                        <table className='topNFTs_table'>
                            <tr className='table_row'>
                                <th className='table_heading'>S No</th>
                                <th className='table_heading'> Collection	</th>
                                <th className='table_heading'>Type</th>
                                <th className='table_heading'>Volume</th>
                                <th className='table_heading'>Change (%) </th>
                                <th className='table_heading'>Sales</th>
                                <th className='table_heading'>Min Price (24H)  </th>
                                <th className='table_heading'>Max Price (24H) </th>
                                <th className='table_heading'>Transfers</th>
                                <th className='table_heading'>Owners</th>
                                <th className='table_heading'>Total Assets  </th>
                            </tr>
                            <tr className='table_row'>
                                <td className='table_text'>1</td>
                                <td className='table_text'><img src={web} /> The Origin Pass</td>
                                <td className='table_text'><span className='btn_v'>ERC-721</span></td>
                                <td className='table_text'>2,508.5 MATIC	</td>
                                <td className='table_text r'>-0.18%</td>
                                <td className='table_text'>3</td>
                                <td className='table_text'>799 MATIC</td>
                                <td className='table_text'> 850 MATIC</td>
                                <td className='table_text'>20,570</td>
                                <td className='table_text'>785</td>
                                <td className='table_text'>999</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default TopNFTs
