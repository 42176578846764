import React from 'react'
import web from '../images/wETH_32.webp'

const NFTTopMints = () => {
  return (
    <>
       <section className='sec01_p'>
        <div className='container'>
            <div className='box1'>
                <div className='row'>
                    <div className='col-lg-9 col-md-8 col-sm-6'>
                        <h3 className='transactions_title b'>NFT Top Mints</h3>
                        <p className='trans_title bolor_b'>( Showing the top 33 NFT )</p>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 text-end'>
                    <nav aria-label="Page navigation example d-flex">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
                <div className='table_over'>
                    <div className='table_main'>
                        <table className='NFTTopMints_table'>
                            <tr className='table_row'>
                                <th className='table_heading'>S No</th>
                                <th className='table_heading'> Collection	</th>
                                <th className='table_heading'> Type	</th>
                                <th className='table_heading'>Mints</th>
                                <th className='table_heading'>Unique Minters	</th>
                                <th className='table_heading'>Total Owners</th>
                                <th className='table_heading'>Total Assets</th>
                            </tr>
                            <tr className='table_row'>
                                <td className='table_text'>1</td>
                                <td className='table_text'><img src={web} /> The Origin Pass</td>
                                <td className='table_text'><span className='btn_v'>ERC-721</span></td>
                                <td className='table_text'>3,000(N/A)</td>
                                <td className='table_text'>3,000(100.00%)</td>
                                <td className='table_text'>0</td>
                                <td className='table_text'>34,984,844</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default NFTTopMints
